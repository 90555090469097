var require = {
    baseUrl: '/',
    waitSeconds: 20,
    map: {
        '*': {
            jquery: 'jquery-module-merge',
            knockout: 'lib/knockout-extra/main',
            moment: 'lib/moment-extra',
            react: 'lib/external/react',
            'react-dom/client': 'lib/external/react-dom',
        },
    },
};
